
::-webkit-scrollbar {
  display: none; 
}
.show-script {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .script-thumb {
    padding: 30px 0 29px 33px;
  }
  .script-window {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4088px;
    width: 100%;
    background: url("../../../assets/image/script_background.png");
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: relative;
    .script-title {
      position: absolute;
      top: 1.4%;
      left: 50%;
      margin-left: -10%;
      width: 100%;
      height: 100px;
      background: url("../../../assets/image/script_title.png");
      background-repeat: no-repeat;
      span {
        position: absolute;
        left: 4.6%;
        top: 25%;
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .script-main {
      margin: 116px 98px 100px 98px;
      background: #ffffff;
      border-radius: 20px;
      height: 95%;
      width: 100%;
      .no-data-icon {
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .no-data-font {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          font-weight: bold;
          color: #a8a8b8;
          margin-bottom: 30px;
        }
      }
      .script-body-item {
        width: 100%;
        position: relative;
        top: 3%;
        .common-title {
          margin: 10px 0 0 114px;
          display: flex;
          background: #e8edf9;
          width: 202px;
          height: 37px;
          border-radius: 13px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          line-height: 37px;
          .common-tag {
            width: 37px;
            height: 37px;
            background: #2821fc;
            border-radius: 13px 13px 0px 13px;
            color: #ffffff;
          }
          .common-item {
            margin-left: 19px;
          }
        }
        .common-main {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          width: 100%;
          .common-main-box {
            display: flex;
            .common-time {
              font-weight: bold;
              padding-left: 5%;
            }
            .common-timeline {
              margin-left: 1.5%;
              border-right: 2px dashed #cad4fd;
              height: auto;
            }
            .common-content {
              margin: 15px 0 0 30px;
              width: 85%;
              height: auto;
              background: #f8f8f8;
              .content-one {
                padding: 18px 17px 20px 21px;
              }
              .content-two {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
